import React, { Component } from 'react';
// @ts-ignore
import { connect, ConnectedProps } from 'react-redux';
// @ts-ignore
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from 'react-intl';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import messages from './WelcomeScreen.messages';
import { finishFirstVisit } from '../App/App.actions';
import Information from './Information';
import Login from '../Account/Login';
import SignUp from '../Account/SignUp';
import CboardLogo from './CboardLogo/CboardLogo.component';
import ResetPassword from '../Account/ResetPassword';

import './WelcomeScreen.css';

interface OwnProps {
  heading?: string;
  text?: string;

}

interface State {
  activeView: string;
  dialogWithKeyboardStyle:{
    dialogStyle: any;
    dialogContentStyle: any;
  }
}

const mapDispatchToProps = {
  finishFirstVisit
};

const connector = connect(
  null,
  mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OwnProps & WrappedComponentProps;

class WelcomeScreen extends Component<Props, State> {
  state: State = {
    activeView: '',
    // keyboard: { isKeyboardOpen: false, keyboardHeight: undefined },
    dialogWithKeyboardStyle: {
      dialogStyle: {},
      dialogContentStyle: {}
    }
  };

  handleActiveView = (activeView: string) => {
    this.setState({
      activeView
    });
  };

  onResetPasswordClick = () => {
    this.resetActiveView();
    this.handleActiveView('forgot');
  };

  resetActiveView = () => {
    this.setState({
      activeView: ''
    });
  };

  render() {
    const { finishFirstVisit, heading, text } = this.props;
    const { activeView, dialogWithKeyboardStyle } = this.state;

    return (
      <div className="WelcomeScreen">
        <div className="WelcomeScreen__container">
          <div className="WelcomeScreen__content">
            <Information heading={heading} text={text} />
          </div>
          <div className="WelcomeScreen__logo">
            <CboardLogo />
          </div>
          <footer className="WelcomeScreen__footer">
            <Button
              className="WelcomeScreen__button WelcomeScreen__button--login"
              variant="contained"
              onClick={() => this.handleActiveView('login')}
            >
              <FormattedMessage {...messages.login} />
            </Button>
            {/*
            <Button
              className="WelcomeScreen__button WelcomeScreen__button--signup"
              variant="contained"
              color="primary"
              onClick={() => this.handleActiveView('signup')}
            >
              <FormattedMessage {...messages.signUp} />
            </Button>
            */}
          </footer>
          <div className="WelcomeScreen__links">
            <Link href="#" rel="noopener noreferrer" color="inherit">
              <FormattedMessage {...messages.privacy} />
            </Link>
            <Link href="#" rel="noopener noreferrer" color="inherit">
              <FormattedMessage {...messages.terms} />
            </Link>
          </div>
        </div>
        <Login
          isDialogOpen={activeView === 'login'}
          onResetPasswordClick={this.onResetPasswordClick}
          onClose={this.resetActiveView}
        />
        <ResetPassword
          isDialogOpen={activeView === 'forgot'}
          onClose={this.resetActiveView}
        />
        <SignUp
          isDialogOpen={activeView === 'signup'}
          onClose={this.resetActiveView}
          dialogWithKeyboardStyle={dialogWithKeyboardStyle}
        />
      </div>
    );
  }
}

export default connector(injectIntl(WelcomeScreen));
